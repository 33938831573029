import Vue from 'vue'

import VueRouter from 'vue-router'
import router from '@/router/index'


import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'


Vue.use(VueRouter)
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
