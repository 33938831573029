import VueRouter from 'vue-router'

const router = new VueRouter({
    routes: [
        {
          path: '/',
          redirect: '/home'
        },
        {
            path: '/home',
            component: () => import('@/views/home/HomePage')
        }
    ]
})

export default router